import React from "react"
import style from "./spinner.module.scss"

class Spinner extends React.Component {
    render() {
        return (
            <div className={style.spinner}>
                <div className={`${style.spinner__bar} ${style.spinner__barTop}`} />
            </div>
        )
    }
}

export default Spinner
