import PropTypes from "prop-types";
import React from "react";
import { Link } from "gatsby";
import style from "./pagination.module.scss"
// import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
// import theme from "../theme.yaml"

const Pagination = props => {

    const { currentPage, countPages } = props.paginationData;
    const isFirst = currentPage === 1 || !currentPage;
    const isLast = currentPage === countPages;
    const prevPage = "/photos?page=" + (currentPage - 1 > 1 ? (currentPage - 1) : "/photos");
    const nextPage = "/photos?page=" + (currentPage + 1);

    var visiblePageNumbers = selectRelevantPageLinks(currentPage, countPages)

    return (
        <React.Fragment>
            <nav className={style.pagination}>
                <ul className={style.pagination__list}>
                {/* "Prev" arrow */}
                {!isFirst && (
                    <li className={`${style.pagination__button} ${style.pagination__buttonLeft}`}>
                        <Link to={prevPage} rel="prev">
                            <span className="prev-arrow">
                                {/*<FaArrowLeft/>*/}
                            </span>
                        </Link>
                    </li>
                )}

                {/* Numbered page links. */}
                {countPages > 1 && (
                    <React.Fragment>
                        {visiblePageNumbers.map(num => {
                            if (isNaN(num)) {
                                return <span key={`dots-${num}`}>.....</span>
                            }
                            return (
                                <span className="pagination-numbers" key={`page-${num}`} >
                                    <Link
                                        to={`/photos?page=${num === 1 ? "" : num}`}
                                        className="pagination-numbers"
                                    >
                                        {num}
                                    </Link>
                                </span>
                            )
                        })}
                    </React.Fragment>
                )}

                {/* "Next" arrow */}
                {!isLast && (
                    <li className={`${style.pagination__button} ${style.pagination__buttonRight}`}>
                        <Link to={nextPage} rel="next">
                            <span className="next-arrow">
                                {/*<FaArrowRight/>*/}
                            </span>
                        </Link>
                    </li>
                )}
                </ul>
            </nav>
        </React.Fragment>
    );
}

function selectRelevantPageLinks(currentPage, countPages) {
    let visiblePageNumbers = []
    if (countPages <= 10) {
        /* If there are not too much, show everything. */
        for (let i=1; i<=countPages; i++) {
            visiblePageNumbers.push(i)
        }
    } else {
        /* Always show beginning, end, current, and around current. */
        if (currentPage <= 5) {
            /* If beginning and current are not too far, we don't want to "dot dot" between them. */
            for (let i=1; i<currentPage; i++) {
                visiblePageNumbers.push(i)
            }
        } else {
            visiblePageNumbers.push(1)
            visiblePageNumbers.push("dots-left-half")
            visiblePageNumbers.push(currentPage-2)
            visiblePageNumbers.push(currentPage-1)
        }
        visiblePageNumbers.push(currentPage)
        if (currentPage >= countPages-4) {
            /* If current and end are not too far, we don't want to "dot dot" between them. */
            for (let i=currentPage+1; i<countPages; i++) {
                visiblePageNumbers.push(i)
            }
        } else {
            visiblePageNumbers.push(currentPage+1)
            visiblePageNumbers.push(currentPage+2)
            visiblePageNumbers.push("dots-right-half")
        }
        if (currentPage !== countPages) {
            visiblePageNumbers.push(countPages)
        }
    }
    return visiblePageNumbers
}

Pagination.propTypes = {
    paginationData: PropTypes.object.isRequired
};

export default Pagination;
