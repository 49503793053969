import React from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"
import style from "./paginated-image-list.module.scss"

const AlbumLoop = props => {
    const image = props.item.pictureNode;
    return (
        <li key={props.index} className={style.photos__item}>
            <Link
                to={`/photos/${image.name}`}
                className={style.photos__link}
                state={{figure: image}}
                noreferrer="true"
                rel="noopener"
            >
                <figure>
                    <Img
                        className={style.photos__wrapper}
                        fluid={{...image.childImageSharp.fluid, maxWidth: 400, aspectRatio: 4/3}}
                        placeholderClassName="img-placeholder"
                    />
                    <figcaption className={`u-mgb--1 u-txt--left ${style.photos__caption}`}>
                        { props.item.pictureNode.relativePath }
                    </figcaption>
                </figure>
            </Link>
        </li>
    )
}

export default AlbumLoop
