import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { GlobalStateContext } from "../components/globalState"
import PaginatedImageList from "../components/_paginated-album/paginated-image-list"

class PaginatedPageTemplate extends React.Component {

    render() {
        return (
            <Layout sectionClass="photoAlbum">
                <GlobalStateContext.Consumer>
                    {globalState => (
                        <>
                            <SEO title="Photos" />
                            <PaginatedImageList globalState={globalState} pageContext={this.props.pageContext} />
                        </>
                    )}
                </GlobalStateContext.Consumer>
            </Layout>
        )
    }
}

export default PaginatedPageTemplate
