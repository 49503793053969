import React from "react"
import { InfiniteScroll } from "../infiniteScroll"
import AlbumPage from "./album-page"
import Pagination from "../_pagination/pagination"
import Spinner from "../_spinner/spinner"
import style from "./paginated-image-list.module.scss"

class paginatedImageList extends React.Component {

    constructor(props) {
        super(props)
        if (!props.globalState.items || !props.globalState.useInfiniteScroll) {
            props.globalState.updateState({
                items: props.pageContext.pageImages,
                cursor: props.pageContext.currentPage+1
            })
        }
    }

    componentDidMount() {
        this.props.globalState.updateState({
            isLoading: false
        })
    }

    render() {
        const g = this.props.globalState
        const pageContext = this.props.pageContext
        const paginationData = {
            currentPage: pageContext.currentPage,
            countPages: pageContext.countPages,
            useInfiniteScroll: g.useInfiniteScroll
        }

        /*
         * currentlyVisibleItems typically come from global state.
         * In other cases we simply render the items of this page (corresponds to a path like "/", "/2", "/3",...)
         * The other cases are:
         * - If a user has JS disabled (we won't be able to manipulate global state).
         * - The very first render on initial pageload. 
         */
        const currentlyVisibleItems = g.items || pageContext.pageImages
        return (
            <>
                <InfiniteScroll
                    throttle={18}
                    threshold={1800}
                    isLoading={g.isLoading}
                    hasMore={g.hasMore(pageContext)}
                    onLoadMore={g.loadMore}
                >
                    <AlbumPage items={currentlyVisibleItems} />
                </InfiniteScroll>

                {/* Notification for demo purposes. */}
                {g.useInfiniteScroll && !g.hasMore(pageContext) && !g.isLoading && (
                    <div/>
                )}

                {/* Loading spinner. */}
                {g.isLoading && (
                    <Spinner />
                )}

                {/* Fallback to Pagination for non JS users. */}
                {g.useInfiniteScroll && (
                    <noscript>
                        <style>
                            {`.spinner { display: none !important; }`}
                        </style>
                        <Pagination paginationData={paginationData} />
                    </noscript>
                )}

                {/* Fallback to Pagination on toggle (for demo) and also on error. */}
                {!g.useInfiniteScroll && (
                    <Pagination paginationData={paginationData} />
                )}
            </>
        )
    }
}

export default paginatedImageList
