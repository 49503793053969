import React from "react"
import AlbumLoop from "./album-loop"
import style from "./paginated-image-list.module.scss"

const AlbumPage = ({ items }) => {
    return (
        <section className={style.photos}>
            <ul className={style.photos__grid}>
                {items.map((item, index) =>
                    <AlbumLoop item={item} key={index}/>
                )}
            </ul>
        </section>
    ) 
}

export default AlbumPage;
